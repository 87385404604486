// @flow

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useLocation from './useLocation';
import useId from './useId';

type useTablePropsTypes = {
  tableApp?: string,
  tableTab?: string,
  tableItem?: string,
  tableSettings?: Object
};

type useTablePropsReturnType = {
  app: string,
  tab: string | false,
  item: string,
  settings: Object
};

function useTableProps({ tableApp, tableTab, tableItem, tableSettings }: useTablePropsTypes): useTablePropsReturnType {
  const { app: appApp, tab: appTab, item: appItem } = useLocation();
  const UseId = ({ app, tab }) => useId({ app, tab });
  const UseSelector = ({ app, tab }) => useSelector(state => state.bsn.tables[app][tab]);
  const UseAccess = ({ app }) =>
    useSelector(state => {
      const isTab = (tableTab || appTab) !== false;
      let accessTab = tableTab || appTab;
      if (!isTab) {
        const accessObject = state.bsn.user.access.apps[app];
        const accessArray = Object.keys(accessObject).filter(a => accessObject[a]);
        accessTab = accessArray.includes(accessTab) ? accessTab : accessArray[0];
      }
      return accessTab;
    });
  const app = useMemo(() => tableApp || appApp, [tableApp, appApp]);
  const tab = UseAccess({ app });
  const item = useMemo(() => tableItem || (appItem !== 0 && appItem) || UseId({ app, tab }), [
    tableItem,
    appItem,
    app,
    tab
  ]);
  const settings = tableSettings || UseSelector({ app, tab });

  return { app, tab, item, settings };
}

export default useTableProps;
