//  1024 size of 1 kb in disk as disk reads sizes powers of 2
//  MB is 1000 more of kb
const LOGO_SETTINGS = Object.freeze({
  maxLogoSizeMB: 5,
  get maxLogoSize() {
    return 1024 * 1000 * this.maxLogoSizeMB;
  },
  acceptedFileTypes: '.png, .jpg, .jpeg',
  allowedMultipleFiles: false
});

const DOCUMENT_SETTINGS = {
  maxFileSize: 120000000,
  acceptedFileTypes: '.doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls',
  allowedMultipleFiles: false
};

const MAX_CAMPAIGNS_USED = 3;

const COMMON_CONST = { LOGO_SETTINGS, DOCUMENT_SETTINGS, MAX_CAMPAIGNS_USED };

export default COMMON_CONST;
