import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { Button } from '@trustsecurenow/components-library';
import { LazyIcon } from 'components/icons';
import clients from 'helpers/apis/clients';

type ClientsUsersDownloadReportButtonType = {
  client_id: string
};

const ClientsUsersDownloadReportButton = ({ client_id, color }: ClientsUsersDownloadReportButtonType) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const printClientReport = () => {
    setLoading(true);
    clients
      .getClientReport(client_id)
      .then(res => window.open(res.data.url))
      .catch(error => notify(`${error.response}`, error))
      .finally(() => setLoading(false));
  };

  return (
    <Button
      onClick={e => {
        e.stopPropagation();
        printClientReport();
      }}
      sx={{ mr: 1 }}
      disabled={loading}
      color={color}
    >
      {!loading ? (
        <>
          <LazyIcon component="Download" color="colorCommonWhite" mr={1} size={0.9} />
          Download Client Report
        </>
      ) : (
        <>
          <CircularProgress style={{ color: 'white' }} size={18} thickness={3} />
          Loading...
        </>
      )}
    </Button>
  );
};

export default ClientsUsersDownloadReportButton;
