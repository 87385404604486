import apiClient from '../apiClient/apiClient';

import { getStorage } from '../storage';
import trainingInformation from './trainingInformation';
import { buildQueryString } from 'helpers/utils';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_16;
const reportAPIGWID = process.env.REACT_APP_BASE_URL_18;
const baseURL = `https://${apiGWID}.${base}/${env}`;
const reportBaseURL = `https://${reportAPIGWID}.${base}/${env}`;

const preAssessments = {};

const headers = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
})

preAssessments.getUrl = clientId => {
  return apiClient.get({
    url: `${baseURL}/${clientId}/preassessments/`,
    headers: headers()
  });
};

preAssessments.fetchAssessmentData = (clientId, type) => {
  return apiClient.get({
    url: `${baseURL}/${clientId}/preassessments/?type=${type}`,
    headers: headers()
  });
};

preAssessments.detectAssessment = (preassessmentId, clientId) => {
  return apiClient.get({
    url: `${baseURL}/preassessments/${preassessmentId}/?client_id=${clientId}`,
    headers: headers()
  });
};

preAssessments.updateEmployeeCount = (clientId, data) => {
  return apiClient.put({
    url: `${baseURL}/${clientId}/preassessments/`,
    // headers: trainingInformation.getRequestHeaders(), // why trainingInformation ?
    headers: headers(),
    data
  });
};

preAssessments.getUsers = (clientId, queryParams) => {
  const queryString = buildQueryString(queryParams)
  return apiClient.get({
    url: `${baseURL}/${clientId}/quiz-users/${queryString}`,
    headers: headers(),
  });
};

preAssessments.getPreassessmentReport = (client_id, preassesment_id) => {
  return apiClient.get({
    url: `${reportBaseURL}/reports/${client_id}/preassessment_report/${preassesment_id}`,
    headers: headers()
  });
};

preAssessments.getPreemploymentReport = (user_id, preassesment_id) => {
  return apiClient.get({
    url: `${reportBaseURL}/reports/${user_id}/preemployment_report/${preassesment_id}`,
    headers: headers()
  });
};

export default preAssessments;
