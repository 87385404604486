import React, { useState, useEffect, useCallback } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useWindowSize } from 'hooks';
import theme from 'conf/theme/theme';
import { decodeId, getURLQueryParams } from 'helpers/utils';
import preAssessments from 'helpers/apis/preAssessments';
import cognito from 'helpers/apis/cognito';

import { usePreassessment } from './hooks';
import { PreassessmentLayout } from './style';
import { PreassessmentGetStarted, PreassessmentQuiz } from './pages';
import { PreassessmentProvider } from './contexts/PreassessmentContext';
import PreassessmentEmailStep from './components/getStarted/PreassessmentEmailStep';
import PreassessmentUserInfo from './components/getStarted/PreassessmentUserInfo';
import PreassessmentVerifyStep from './components/getStarted/PreassessmentVerifyStep';
import PreassessmentInfo from './components/getStarted/PreassessmentInfo';
import PreassessmentAllSet from './components/getStarted/PreassessmentAllSet';
import { PageError } from '../../components';

const getDescription = (
  arg1,
  arg2
) => `Cybersecurity is important to us! As part of our commitment to protecting our sensitive company, employee, and \
customer data, we request all ${arg1} complete this ${arg2} cybersecurity assessment. This assessment \
helps us pinpoint your cybersecurity strengths and weaknesses and determine where additional training may be \
needed.`;

const assessments = {
  baseline: {
    label: 'Baseline Employee',
    description: getDescription('employees', 'baseline'),
    steps: [
      { title: 'Start', component: <PreassessmentEmailStep /> },
      { title: 'Verify', component: <PreassessmentVerifyStep /> },
      { title: 'Begin', component: <PreassessmentInfo /> }
    ]
  },
  preemployment: {
    label: 'Pre-Employment',
    description: getDescription('candidates', 'pre-employment'),
    steps: [
      { title: 'Start', component: <PreassessmentUserInfo /> },
      { title: 'Verify', component: <PreassessmentVerifyStep /> },
      { title: 'Begin', component: <PreassessmentAllSet /> }
    ]
  }
};

const pages = {
  start: <PreassessmentGetStarted />,
  quiz: <PreassessmentQuiz />
};

const Preassessment = props => {
  const usePreassessmentState = usePreassessment(); //
  const [activePage, setActivePage] = useState('start');
  const [assessmentData, setAssessmentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [, height] = useWindowSize();

  const getAssesmentData = useCallback(async () => {
    const urlQueryPrams = getURLQueryParams(props.location.search);
    // console.log('urlQueryPrams', urlQueryPrams);
    const { client_id, quiz_id = '', preassessment_id = '', email: emailEncoded = '' } = urlQueryPrams;

    if (!client_id) return;

    // get assessment data
    let data = {
      type: 'baseline',
      user: {
        emailEncoded,
        email: decodeId(emailEncoded)
      },
      client_id,
      quiz_id,
      preassessment_id
    };

    if (preassessment_id) {
      // preemployment or new baseline
      await preAssessments
        .detectAssessment(preassessment_id, client_id)
        .then(({ data: { quiz_id, type } }) => {
          // update quiz_id, type
          data = { ...data, quiz_id, type };
        })
        .catch(err => {
          console.log('err', err);
          setError(err);
        });
    }

    if (emailEncoded) {
      // preemployment
      const searchQuery = `?client_id=${client_id}&email=${emailEncoded}`;

      await cognito
        .getExternalUserData(searchQuery)
        .then(({ data: { user, picklist } }) => {
          // add user
          data = { ...data, user }; // first_name,last_name,email,job_title,job_title_id
        })
        .catch(err => {
          console.log('err', err);
          setError(err);
        });
    }
    return data;
  }, [props.location.search]);

  useEffect(() => {
    getAssesmentData()
      .then((data = {}) => {
        setAssessmentData(data);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [getAssesmentData]);

  useEffect(() => {
    // set assessmentData.user.email to the entered email upon change in case of baseline assessment
    setAssessmentData(s => ({ ...s, user: { ...s.user, email: usePreassessmentState.email } }));
  }, [usePreassessmentState.email]);

  // console.log('assessmentData', assessmentData);

  const page = pages[activePage];

  return loading ? (
    <div>Loading</div>
  ) : error || !assessmentData.client_id ? (
    <PageError
      title="Oops!"
      subtitile="There was a problem with the page?"
      description="Try and refresh the page, if the problem still exists then Contact Us"
    />
  ) : (
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      <PreassessmentProvider
        value={{
          assessment: assessments[assessmentData.type],
          ...assessmentData,
          ...usePreassessmentState,
          gotoStartPage: () => setActivePage('start'),
          gotoQuizPage: () => setActivePage('quiz')
        }}
      >
        <PreassessmentLayout style={{ height: `${height}px` }}>{page}</PreassessmentLayout>
      </PreassessmentProvider>
    </MuiThemeProvider>
  );
};

export default Preassessment;
