// @flow
import React, { useState, type ComponentType } from 'react';
import { isValidEmail } from 'helpers';
import styled, { css } from 'styled-components';
import {
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog as MuiDialog,
  IconButton,
  Box,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Container, TextField } from 'components';
import { ButtonCancel, ButtonSubmit as ButtonSubmitRed } from './ComponentTypes';
import { LazyApp } from '../../apps';
import { ButtonSubmit as ButtonSubmitGreen } from '../modal/ComponentTypes';

type DialogTypes = {
  title: string,
  message: string,
  setOpen: Function,
  onSubmit: Function,
  open: boolean,
  maxWidth: string,
  buttonSubmit: string,
  disabledSubmit: boolean,
  email?: boolean,
  deleteMessage?: boolean,
  component?: string | null,
  variant?: string,
  onlyOk?: boolean
};

const DialogMui = styled(({ ...props }) => <MuiDialog {...props} />)`
  &.Variant-standard {
    && {
      & h2 {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  &.Variant-deleteMessage {
    && {
      text-align: center;
      & h2 {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  && {
    ${({ submitButtonPosition }) => {
      return (
        submitButtonPosition === 'top' &&
        css`
          .dialog-buttons {
            order: 0;
            margin-bottom: 0;
            margin-top: 55px;
          }
          .dialog-content {
            order: 1;
          }
        `
      );
    }}

    ${({ customActions }) => {
      return (
        customActions &&
        css`
          .dialog-content {
            padding: 0;
          }
        `
      );
    }}


    .close-button {
      position: absolute;
      top: 14px;
      right: 12px;
      z-index: 1000;
    }

    .close-icon {
      width: 22px;
      height: 22px;
    }
  }
`;

const SubmitButton = styled(ButtonSubmitGreen)`
  && {
    margin-left: 24px;
  }
`;

const Dialog = ({
  title,
  titleComponent = null,
  message,
  setOpen,
  onSubmit,
  open = false,
  maxWidth = 'sm',
  buttonSubmit,
  disabledSubmit = false,
  email,
  component,
  variant = 'standard' || 'deleteMessage',
  deleteMessage,
  onlyOk,
  children,
  submitButtonPosition = 'bottom',
  closeButton,
  customActions = false,
  ...props
}: DialogTypes) => {
  const [emailValue, setEmail] = useState(null);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const submit = async () => {
    setIsSubmitting(true);
    await onSubmit(emailValue);
    setIsSubmitting(false);
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);

    if (!isValidEmail(e.target.value)) setInvalidEmail(true);
    else setInvalidEmail(false);
  };
  const onKeyDown = e => {
    if (e.keyCode === 32) e.preventDefault();
  };

  return (
    <div>
      <DialogMui
        {...props}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
        fullWidth
        classes={{ root: `Variant-${variant}` }}
        submitButtonPosition={submitButtonPosition}
        customActions={customActions}
      >
        {titleComponent}
        {title && <DialogTitle>{title}</DialogTitle>}
        {closeButton && (
          <Box m={!customActions ? 3 : 0}>
            <IconButton onClick={handleClose} className={'close-button'}>
              <CloseIcon className='close-icon' />
            </IconButton>
          </Box>
        )}

        <DialogContent className={'dialog-content'}>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
          {email && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={emailValue}
              label="Email Address"
              type="email"
              fullWidth
              onChange={handleEmailChange}
              error={invalidEmail}
              helperText={invalidEmail && 'Please enter a valid email address'}
            />
          )}
          {component !== null && <LazyApp component={component} />}
          {children}
        </DialogContent>
        {variant === 'deleteMessage' ? (
          <Container.Grid direction="column" alignItems="center" justify="center" mb={3}>
            <ButtonSubmitRed
              onClick={onSubmit}
              color="primary"
              autoFocus
              onKeyDown={onKeyDown}
              disabled={disabledSubmit || !open}
            >
              {buttonSubmit}
            </ButtonSubmitRed>
            <ButtonCancel onClick={handleClose} color="primary">
              Cancel
            </ButtonCancel>
          </Container.Grid>
        ) : (
          <Container.Grid
            alignItems="flex-end"
            justify="flex-end"
            mb={!customActions ? 3 : 0}
            pr={3}
            className={'dialog-buttons'}
          >
            {!onlyOk && (
              <ButtonCancel mr={3} onClick={handleClose} color="primary">
                Cancel
              </ButtonCancel>
            )}
            {buttonSubmit && (
              <SubmitButton
                ml={3}
                onClick={submit}
                color="primary"
                autoFocus
                disabled={(email && invalidEmail) || isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : buttonSubmit}
              </SubmitButton>
            )}
          </Container.Grid>
        )}
      </DialogMui>
    </div>
  );
};

Dialog.defaultProps = {
  email: false,
  deleteMessage: true,
  component: null,
  variant: 'deleteMessage'
};

export default Dialog;
