import React from 'react';
import { Container } from 'components';
import cognito from 'helpers/apis/cognito';
import { usePreassessmentContext } from '../../contexts';
import { PreassessmentButton, PreassessmentInfoContainer, PreassessmentEmailStepTitle } from '../../style';
import { useNotify } from 'react-admin';
import { Notification } from 'ra-ui-materialui';

const PreassessmentAllSet = () => {
  const {
    // dispatch,
    // client_id,
    gotoQuizPage,
    disabled,
    userState,
    user: { first_name, last_name, job_title_id },
  } = usePreassessmentContext();

  const notify = useNotify();

  const handleSubmit = async () => {
    if (disabled) return;

    await cognito
      .updateExternalUser({
        first_name,
        last_name,
        job_title_id: job_title_id.toString()
      })
      .then(res => {
        if (res.status === 200) gotoQuizPage();
      })
      .catch(err => {
        if (err.response?.data?.code === 400) {
          notify('You have already taken this assessment', { type: 'warning' });
        }
        console.log('err', err);
        console.log('err.response', err.response);
      });
  };

  return (
    <>
      <PreassessmentInfoContainer>
        <PreassessmentInfoContainer>
          <Container.Grid sm={12}>
            <PreassessmentEmailStepTitle>All set! You're ready to get started!</PreassessmentEmailStepTitle>
          </Container.Grid>

          <Container.Grid mt={3} sm={12}>
            <PreassessmentButton disabled={disabled} onClick={handleSubmit} isStarted={userState !== 'start'}>
              {userState === 'start' ? 'Begin Assessment' : 'Continue Assessment'}
            </PreassessmentButton>
          </Container.Grid>
        </PreassessmentInfoContainer>
      </PreassessmentInfoContainer>
      <Notification />
    </>
  );
};

export default PreassessmentAllSet;
