// @flow
import React, { type Element, useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import styled, { keyframes } from 'styled-components';

const pulsingText = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.5;
  }
`;

const Speedometer = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};

  &:before {
    content: 'Loading...';
    display: ${({ hasData }) => (hasData ? 'none' : 'block')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    color: ${({ text }) => text};
    font-size: 1.8rem;
    animation: ${pulsingText} 1s infinite;
  }

  .speedometer {
    opacity: ${({ hasData }) => (hasData ? 1 : 0.2)};
  }
`;

type ChartSpeedometerTypes = {
  chartData: number,
  chartSettings: {
    div: {
      width: string,
      height: string,
      margin: string
    },
    speedometer: {
      maxValue: number,
      customSegmentStops: Array<string | number>,
      segmentColors: Array<string>,
      needleColor: string
    }
  },
  hasData: boolean
};

const ChartSpeedometer = ({
  hasData,
  chartData,
  chartSettings: { div, speedometer }
}: ChartSpeedometerTypes): Element<*> => {
  const [data, setData] = useState(0);

  useEffect(() => {
    if (hasData) {
      setTimeout(() => {
        setData(chartData);
      }, 300);
    }
  });

  return (
    <Speedometer {...div} hasData={hasData}>
      <ReactSpeedometer {...speedometer} value={data} />
    </Speedometer>
  );
};

export default ChartSpeedometer;
