// @flow
import React, { useEffect, useState, type ComponentType } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ChartPie from '../charts/ChartPie';

const DashboardPieChartWidgetWrapper: ComponentType<*> = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  padding: var(--spacing) calc(var(--spacing) * 2);
  background-color: var(--backgroundPaper);
  border-radius: 10px;
  margin: 0 0 var(--spacing);
  min-height: 92px;
  margin-left: ${({ right }) => (right ? 'var(--spacing)' : '0')};
`;

const DashboardPieChartWidgetTitle: ComponentType<*> = styled.h3`
  font-size: 1.3rem;
  color: var(--colorDefault);
  flex-basis: 33%;
  background-color: ${({ hasData, color }) => (hasData ? 'transparent' : color)};
  opacity: ${({ hasData }) => (hasData ? 1 : 0.4)};
  height: ${({ hasData }) => (hasData ? 'auto' : '1.6rem')};
`;

const DashboardPieChartWidgetCaption: ComponentType<*> = styled.div`
  text-align: center;
  color: var(--colorBaseLight1);
  opacity: ${({ hasData }) => (hasData ? 1 : 0.4)};
`;

const DashboardPieChartWidgetMax: ComponentType<*> = styled.span`
  font-size: 1rem;
  display: block;
  color: var(--colorDefault);
`;

const DashboardPieChartWidgetValue: ComponentType<*> = styled.span`
  font-size: 2rem;
  display: block;
  color: var(--colorDefault);
`;

type DashboardPieChartWidgetType = {
  title: string,
  chart: Object,
  colors: {
    // TODO: remove this colors when all files using this component ware update using css variables.
    nav_bg: string,
    nav_copy: string
  },
  hasData: boolean,
  right?: boolean
};

const DashboardPieChartWidget = ({ right, ...props }: DashboardPieChartWidgetType) => {
  const { title, chart, hasData } = props;
  const { colorScheme: theme } = useSelector(rxState => rxState.bsn.partnerProfile);
  const [fu, forceUpdate] = useState(false);
  const { chartData } = chart;
  const { nav_bg, nav_copy, alt_bg } = theme;
  useEffect(() => {
    if (!hasData) {
      setTimeout(() => forceUpdate(!fu), 300);
    }
  }, [fu, forceUpdate, hasData]);

  return (
    <DashboardPieChartWidgetWrapper background={nav_bg} hasData={hasData} right={right}>
      <DashboardPieChartWidgetTitle color={nav_copy} hasData={hasData}>
        {hasData && title}
      </DashboardPieChartWidgetTitle>
      <ChartPie {...chart} hasData={hasData} />
      <DashboardPieChartWidgetCaption hasData={hasData}>
        <DashboardPieChartWidgetMax color={nav_copy}>
          MAX {hasData ? chartData[1].value + chartData[0].value : 0}
        </DashboardPieChartWidgetMax>
        <DashboardPieChartWidgetValue color={alt_bg}>{hasData ? chartData[0].name : 0}</DashboardPieChartWidgetValue>
      </DashboardPieChartWidgetCaption>
    </DashboardPieChartWidgetWrapper>
  );
};

DashboardPieChartWidget.defaultProps = {
  right: false
};

export default DashboardPieChartWidget;
