// @flow

export const BSN = 'BSN';
export const BSN_GET_LIST = 'BSN_GET_LIST';
export const BSN_GET_ONE = 'BSN_GET_ONE';
export const BSN_SET = 'BSN_SET';
export const BSN_SET_ANY = 'BSN_SET_ANY'; // responsable for bulk updates & deep nested updates
export const BSN_SET_TARGET = 'BSN_SET_TARGET';
export const BSN_SET_APP = 'BSN_SET_APP';
export const BSN_SET_ITEM = 'BSN_SET_ITEM';
export const BSN_SET_ITEMS = 'BSN_SET_ITEMS';
export const BSN_SET_USER = 'BSN_SET_USER';
export const BSN_SET_USER_ALL_NOTIFICATIONS_SEEN = 'BSN_SET_USER_ALL_NOTIFICATIONS_SEEN';
export const BSN_UPDATE_USER_PROFILE = 'BSN_UPDATE_USER_PROFILE';
export const BSN_SET_USER_APPS = 'BSN_SET_USER_APPS';
export const BSN_UPDATE_DARK_MODE = 'BSN_UPDATE_DARK_MODE';
export const BSN_SET_GET_STARTED_NOTIFY = 'BSN_SET_GET_STARTED_NOTIFY';
export const BSN_CLEAR = 'BSN_CLEAR';
export const BSN_SYSTEM = 'BSN_SYSTEM';
export const BSN_SYSTEM_CHANGE_APP = 'BSN_SYSTEM_CHANGE_APP';
export const BSN_SYSTEM_CHANGE_TAB = 'BSN_SYSTEM_CHANGE_TAB';
export const BSN_PARTNER_PROFILE_INFORMATION = 'BSN_PARTNER_PROFILE_INFORMATION';
export const BSN_PARTNER_PROFILE_COLOR_SCHEME = 'BSN_PARTNER_PROFILE_COLOR_SCHEME';
export const BSN_PARTNER_PROFILE_COLOR_SCHEME_UPDATE = 'BSN_PARTNER_PROFILE_COLOR_SCHEME_UPDATE';
export const BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM = 'BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM';
export const BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_UPDATE = 'BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_UPDATE';
export const BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_ITEM = 'BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_ITEM';
export const BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_ITEM_UPDATE =
  'BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_ITEM_UPDATE';
export const BSN_SET_CLIENT_ACCESS = 'BSN_SET_CLIENT_ACCESS';
export const BSN_SET_CLIENT_NEW_ACCESS = 'BSN_SET_CLIENT_NEW_ACCESS';
export const BSN_SET_CLIENT_LOGO = 'BSN_SET_CLIENT_LOGO';
export const BSN_SET_USER_NEWSFEED_APP_ACCESS = 'BSN_SET_USER_NEWSFEED_APP_ACCESS';
// Newsfeed action types
// general
export const NEWSFEED_LICENSE = 'NEWSFEED_LICENSE';
export const NEWSFEED_LICENSE_AGREED = 'NEWSFEED_LICENSE_AGREED';
// posts
export const SET_FEEDS = 'SET_FEEDS';
export const CLEAR_FEEDS = 'CLEAR_FEEDS';
export const EMPTY_FEEDS = 'EMPTY_FEEDS';
export const ADD_FEED = 'ADD_FEED';
export const UPDATE_FEED = 'UPDATE_FEED';
export const UPDATE_FEED_ID = 'UPDATE_FEED_ID';
export const UPDATE_FEED_SCORE = 'UPDATE_FEED_SCORE';
export const UPDATE_FEEDS = 'UPDATE_FEEDS';
export const DELETE_FEED = 'DELETE_FEED';
export const SET_FEED_SEARCH = 'SET_FEED_SEARCH';
export const SET_FEED_SEARCH_BY = 'SET_FEED_SEARCH_BY';
export const SET_FEED_FILTER = 'SET_FEED_FILTER';
export const SET_FEED_SEARCH_FILTER = 'SET_FEED_SEARCH_FILTER';
export const SET_CAN_PIN_POST = 'SET_CAN_PIN_POST';
export const SET_HAS_PINNED_POST = 'SET_HAS_PINNED_POST';
// comments
export const SET_COMMENTS = 'SET_COMMENTS';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const UPDATE_COMMENT_ID = 'UPDATE_COMMENT_ID';
export const DELETE_COMMENT = 'DELETE_COMMENT';
// Notifications
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const MARK_OPENED = 'MARK_OPENED';
export const MARK_ALL_OPENED = 'MARK_ALL_OPENED';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const SET_USER_NOTIFICATION_COUNT = 'SET_USER_NOTIFICATION_COUNT';
export const SET_CAN_FLAG = 'SET_CAN_FLAG';

// Auth action types
export const REDIRECT_TO_FAVORITE = 'REDIRECT_TO_FAVORITE';

// responsable for blocking tab feach request
export const BLOCKED_TAB_OPEN_REQUEST = {
  clients: ['information']
};

// this mainly used to dispatch event from element or functions outside react arcticture to Layout.js
export const NOTIFICATION_CUSTOM_EVENT = 'notify-app';
export const DISPATCH_CUSTOM_EVENT = 'dispatch-app';
