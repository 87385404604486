import React from 'react';
import {
  IndicatorContainer,
  IndicatorTitle,
  PreassessmentWrapperContainer,
  PreassessmentWrapperDescription,
  PreassessmentWrapperLayout,
  PreassessmentWrapperTitle
} from 'apps/preassessment/style';
import Indicator from 'components/charts/Indicator';
import { usePreassessmentContext } from 'apps/preassessment/contexts';


const PreassessmentWrapper = () => {
  const { step, type, assessment={} } = usePreassessmentContext();

  const { label, description, steps=[] } = assessment;

  return (
    <PreassessmentWrapperLayout item md={8} alignItems={'center'}>
      <PreassessmentWrapperContainer>
        <PreassessmentWrapperTitle>{label} Cybersecurity Assessment</PreassessmentWrapperTitle>
        <PreassessmentWrapperDescription>{description}</PreassessmentWrapperDescription>

        <IndicatorContainer alignItems={'center'}>
          <IndicatorTitle>{steps[step - 1].title}</IndicatorTitle>
          <Indicator title={`${step} / ${steps.length}`} value={Math.ceil((step / steps.length) * 100)} />
        </IndicatorContainer>

        {steps[step - 1].component}
      </PreassessmentWrapperContainer>
    </PreassessmentWrapperLayout>
  );
};

export default PreassessmentWrapper;
