// @flow

type Props = {
  filters?: Object,
  pagination?: Object,
  sort?: Object,
  params?: Object,
};

function dataParams({ filters, pagination, sort, startAtZero = false, ...params }: Props) {
  const p = params;

  //cleaning empty params
  for (const [key, val] of Object.entries(params)) {
    if (val) {
      p[key] = val;
    }
  }

  if (filters) {
    p._filter = Object.entries(filters)
      .filter(([_, v]) => v != null && v !== '')
      .map(([key, value]) => `${key}:${value}`)
      .join(',');
  }

  if (pagination) {
    p._start = startAtZero && pagination.page === 0 ? 0 : pagination.page * pagination.perPage + 1;
    p._end = startAtZero && pagination.page !== 0
      ? (pagination.page + 1) * pagination.perPage + 1
      : (pagination.page + 1) * pagination.perPage
  }

  if (sort) {
    p._sort = sort.sortname;
    p._order = sort?.order.toUpperCase();
  }

  return p;
};

export default dataParams;
