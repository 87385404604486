// @flow
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { LineChart as ReLineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const pulsingText = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.5;
  }
`;

const LineChart = styled(ReLineChart)`
  position: relative;

  &:before {
    content: 'Loading...';
    display: ${({ hasData }) => (hasData ? 'none' : 'block')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    color: ${({ text }) => text};
    font-size: 1.8rem;
    animation: ${pulsingText} 1s infinite;
  }

  .recharts-text {
    fill: ${({ text }) => text};
    font-size: 1.1rem;
  }

  .recharts-cartesian-grid {
    line {
      stroke: ${({ text }) => text};
      opacity: 0.1;
    }
  }

  .recharts-yAxis,
  .recharts-xAxis {
    opacity: 0.4;
  }
`;

type ChartLineTypes = {
  chartData: Array<{
    name: string,
    value: string | number
  }>,
  hasData: boolean
};

const ChartLine = (props: ChartLineTypes) => {
  const { chartData, hasData } = props;
  const { nav_bg } = useSelector(rxState => rxState.bsn.partnerProfile.colorScheme);
  const [data, setData] = useState([
    {
      name: 'month 1',
      value: 0
    },
    {
      name: 'month 2',
      value: 0
    },
    {
      name: 'month 3',
      value: 0
    },
    {
      name: 'month 4',
      value: 0
    },
    {
      name: 'month 5',
      value: 0
    },
    {
      name: 'month 6',
      value: 0
    }
  ]);

  useEffect(() => {
    if (hasData) {
      setTimeout(() => {
        setData(chartData);
      }, 300);
    }
  });

  return (
    <ResponsiveContainer>
      <LineChart data={data} text={nav_bg} hasData={hasData}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="1 0" vertical={false} />
        <Tooltip />
        <Line type="monotone" dataKey="value" mirror stroke={nav_bg} strokeWidth="2" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartLine;
