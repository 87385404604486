import styled from 'styled-components';

export const IconContainer = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--backgroundTableHead);
  svg {
    width: 30px;
    height: 30px;
    fill: var(--colorBox);
    color: var(--colorSystemInfo);
  }
`
