// @flow

export const dashboardChartSettingsESS = {
  div: {
    width: '200px',
    height: '123px',
    margin: '10px 0'
  },
  speedometer: {
    maxValue: 800,
    customSegmentStops: [0, 250, 500, 630, 800],
    segmentColors: ['#f78383', '#f78383', '#e8d980', '#8bcc8b'],
    needleColor: '#000',
    fluidWidth: true,
    ringWidth: 30
  }
};
