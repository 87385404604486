import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'hooks';
import { PAGES } from './pages';
import { SRAProvider } from './context/sraContext';

const SRANestedPage = () => {
  const { page, reportId } = useParams();
  const history = useHistory();
  const { app, tab, item } = useLocation();
  const accessApps = useSelector(state => state?.bsn?.user?.access?.apps);

  useEffect(() => {
    const hasAppAccess = accessApps?.hasOwnProperty(app);
    const hasTabAccess = accessApps?.[app]?.[tab];
    const access_token = localStorage.getItem('accessToken');

    const noAccessToEditPage =
      (!sessionStorage.getItem('accessEditSRA') && page === 'editSraReport') || (page === 'editSraReport' && !reportId);
    const noAccessToWorkplanPage = !sessionStorage.getItem('accessWorkplanSRA') && page === 'workPlan';
    const noAccessToOrgProfilePage = !sessionStorage.getItem('accessOrgProfileSRA') && page === 'orgProfile';
    const noAccessToQuestionsPage = !sessionStorage.getItem('accessQuestionsSRA') && page === 'questions';

    // Redirect to Dashboard page
    if ((accessApps && (!hasAppAccess || !hasTabAccess)) || !access_token) {
      history.push('/myDashboard/dashboard');
    }

    // Redirect to SRA page
    if (
      (page && !PAGES[page]?.component) ||
      noAccessToEditPage ||
      noAccessToWorkplanPage ||
      noAccessToOrgProfilePage ||
      noAccessToQuestionsPage
    ) {
      history.push(`/${app}/${tab}${item && item !== page ? `/${item}` : ''}`);
    }
  }, [app, tab, item, accessApps, history, page, reportId]);

  return <SRAProvider>{page && PAGES[page]?.component ? PAGES[page]?.component : null}</SRAProvider>;
};

export default SRANestedPage;
