// @flow
import React from 'react';
import { Button, Container, LazyIcon } from 'components';

type AccordionFooterProps = {
  onSubmit: Function,
  disabled?: boolean
};

const AccordionFooter = ({ onSubmit, disabled }: AccordionFooterProps) => (
  <>
    <Container.Grid item md={12} bb={1} />
    <Container.Grid item md={12} pt={2} justify="flex-end">
      <Button
        backgroundColor="colorSystemInfo"
        backgroundColorHover="colorSystemInfoHover"
        onClick={onSubmit}
        disabled={disabled}
      >
        <LazyIcon component="Save" color="colorCommonWhite" mr={1} />
        Save
      </Button>
    </Container.Grid>
  </>
);

export default React.memo(AccordionFooter);