import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';
import { buildQueryString } from 'helpers/utils';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_9;
const baseURL = `https://${api}.${base}/${env}`;

const dw = {};

dw.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

dw.updateDomainFields = ({ clientId, data }) => {
  const requestOptions = {
    url: `${baseURL}/clients/domainsinfo/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    },
    data
  };
  return apiClient.put(requestOptions);
};

dw.getProhibitedDomains = () => {
  const requestOptions = {
    url: `${baseURL}/clients/blacklistedDomainsList`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    }
  };
  return apiClient.get(requestOptions);
};

dw.getDarkwebReport = ({ clientId, params }) => {
  const requestOptions = {
    url: `${baseURL}/clients/DarkWebReport/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    },
    params
  };
  return apiClient.get(requestOptions);
};

dw.getFilters = (clientId, searchParams) => {
  const requestOptions = {
    url: `${baseURL}/darkweb/dark-web-filters/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    },
    params: searchParams ?? {}
  };
  return apiClient.get(requestOptions);
};

dw.getSettings = clientId => {
  const requestOptions = {
    url: `${baseURL}/darkweb/settings/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    }
  };
  return apiClient.get(requestOptions);
};

dw.getRemediationTraining = () => {
  const requestOptions = {
    url: `${baseURL}/darkweb/remediation-training`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    }
  };
  return apiClient.get(requestOptions);
};

dw.remediateDarkWebQuiz = () => {
  const requestOptions = {
    url: `${baseURL}/DarkWebQuiz/Remediate`,
    headers: dw.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

export default dw;
