import React from 'react';
import { Button, Container, LazyIcon, LoadingStyled, TextField, Typography } from 'components';

const AdminCapabilitiesDWBA = ({ data, loading, clearDwba }) => {
 
  if (loading) return (
    <Container.Grid justifyContent="center">
      <LoadingStyled />
    </Container.Grid>
  );

  return (
    <Container.Grid container md={12} spacing={2} pb={2}>
      <Container.Grid item md={6} lg={4} pr={2} alignItems="center">
        <LazyIcon component="Disable" mr={2} color="colorDefault" size={1.6} />
        <Container.Grid pb={2.6} pt={2.6} direction="column">
          <Typography.h6 mr={2} mt={0.4} mb={0.4}>
            Licenses Allocated
          </Typography.h6>
          <Typography.p fontSize={12} mt={0.4} mb={0.4}>
            DWM Licenses Allocated
          </Typography.p>
        </Container.Grid>
        <TextField
          size="small"
          value={data?.licenses_allocated}
          disabled
        />
      </Container.Grid>

      <Container.Grid item md={6} lg={5} alignItems="center" pl={2} justify="space-evenly">
        {data?.clear_dark_web_cache && (
          <Button
            mr={1}
            onClick={(() => clearDwba('clear_dark_web_cache'))}
            disabled={loading}
          >
            Run Additional Scan
          </Button>
        )}

        {data?.clear_allocated_licenses && (
          <Button
            backgroundColor="colorSystemSuccess"
            backgroundColorHover="colorSystemSuccessHover"
            onClick={(() => clearDwba('clear_allocated_licenses'))}
            disabled={loading}
          >
            Clear Allocated Licenses
          </Button>
        )}
      </Container.Grid>
    </Container.Grid>
  );
};

export default AdminCapabilitiesDWBA;