// @flow
import React, { useEffect } from 'react';
import { useLogin } from 'react-admin';
import { useDispatch } from 'react-redux';

type UserFederatedType = {
  location: {
    search: Object
  }
};

const UserFederated = ({ location }: UserFederatedType) => {
  const login = useLogin();
  const dispatch = useDispatch();

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    const userpoolID = new URLSearchParams(location.search).get('state');
    login({ loginPayload: { auth_code: code, userpool_id: userpoolID }, dispatchRx: dispatch })
      .then(() => {})
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <center> Redirecting...... </center>;
};

export default UserFederated;
