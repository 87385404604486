import React, { useRef, useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Container } from '../types';

const VerificationCodeInputWrapper = styled(Container.Grid)`
  display: inline-flex !important;
  width: auto !important;
  position: relative;

  && {
    margin: 0 calc(var(--spacing) * 2) 0 0 !important;

    ${props => props.theme.breakpoints.down("xs")} {
      margin: 0 0 calc(var(--spacing) * 2) 0 !important;
      justify-content: space-between;
      width: 100% !important;
    }
  }
`

const blinkAnimation = keyframes`
 from { opacity: 1 }
 to { opacity: 0 }
`

const VerificationCodeInputBox = styled.div`
  && {
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: relative;
    border-radius: 5px;
    margin-right: 12px;
    border: 1px solid var(--colorGreyLight2);
    color: var(--colorGreyLight2);
    cursor: text;

    ${props => props.theme.breakpoints.down("xs")} {
      width: 13vw;
      margin-right: 0;
    }

    ${({active}) => active && css`
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 50%;
        width: 1px;
        background-color: #000;
        animation-name: ${blinkAnimation};
        animation-duration: .8s;
        animation-iteration-count: infinite;
      }
  `}

    ${({error}) => error && css`
      background-color: #FF5A6133;
      border-color: #FF5A6133;
      color: #000;
    `}

    &:last-child {
      margin-right: 0;
    }
  }
`

const VerificationCodeInputField = styled.input`
  && {
    position: absolute;
    border: none;
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    outline: none;
    opacity: 0;
    pointer-events: none;

    ${props => props.theme.breakpoints.down("xs")} {
      width: 100%;
    }
  }

`

const VerificationCodeInput = ({onChange, digitsCount = 2, placeholder = "*", error }) => {
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false)

  const inputRef = useRef()
  const values = value.split("");
  const CODE_LENGTH = new Array(digitsCount).fill(0);

  useEffect(() => {
    if(error){
      setValue('')
    }
  }, [error])

  const handleClick = () => {
    inputRef.current.focus();
  };
  const handleFocus = () => {
    setFocused(true)
  };
  const handleBlur = () => {
    setFocused(false)
  };

  const handleChange = (e) => {
    const value = e.target.value;

    if (value.match(/[^0-9]$/)) {
      return false
    }

    setValue((prevState) => {
      if (value.length > CODE_LENGTH.length) {
        return prevState
      }
      const newValue = value.slice(0, CODE_LENGTH.length)

      onChange(newValue)
      return newValue;
    });
  };

  const handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      onChange(value)
    }
  };

  const selectedIndex =
    values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;

  return (
      <VerificationCodeInputWrapper onClick={handleClick}>
        <VerificationCodeInputField
          value={value}
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyUp}
        />
        {CODE_LENGTH.map((v, index) => {
          return (
            <VerificationCodeInputBox
              error={error}
              active={focused && selectedIndex === index}
            >
              {values[index] || placeholder}
            </VerificationCodeInputBox>
          )
        })}
      </VerificationCodeInputWrapper>
  )
}

export default VerificationCodeInput
