// @flow

type errorHandlerType = {
  messgeArgs: Object,
  type: string,
  message: string,
  undoable: boolean
};

type errorHandlerReducerType = {
  type: string,
  payload: errorHandlerType
};

export default (
  previousState: errorHandlerType = {
    messgeArgs: {},
    type: '',
    message: '',
    undoable: false
  },
  { type, payload }: errorHandlerReducerType
): Object | errorHandlerReducerType => (type === 'RA/SHOW_NOTIFICATION' ? payload : previousState);
