// @flow
import React, { type ComponentType } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { PieChart, Pie, Cell, Label } from 'recharts';
import tinycolor from 'tinycolor2';

const shadowPulse = keyframes`
    0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

const PieChartLoading: ComponentType<*> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  animation: ${shadowPulse} 1s infinite;
  border-radius: 50px;
  color: rgba(255, 255, 255, 0.4);
`;

type ChartPieTypes = {
  chartData: Array<*>,
  chartSettings?: {
    pieChart: {
      width: number,
      height: number
    },
    pie: {
      cx: number,
      cy: number,
      innerRadius: number,
      outerRadius: number,
      fill: string,
      stroke: string,
      paddingAngle: number,
      value: string,
      position: string
    },
    label: {
      position: string,
      hide: boolean
    },
    cell: Array<string>
  } | null,
  hasData: boolean
};

const ChartPie = ({ chartData, chartSettings, hasData }: ChartPieTypes) => {
  const { colorScheme: theme } = useSelector(rxState => rxState.bsn.partnerProfile);
  const chartSettingsDefault = 80;
  const { nav_bg, nav_copy } = theme;
  const chartSettingsInit = {
    pieChart: {
      width: chartSettingsDefault,
      height: chartSettingsDefault
    },
    pie: {
      cx: chartSettingsDefault / 2.1,
      cy: chartSettingsDefault / 2.222222,
      innerRadius: chartSettingsDefault / 3.333333,
      outerRadius: chartSettingsDefault / 2.5,
      paddingAngle: 1,
      fill: nav_bg,
      stroke: nav_bg
    },
    label: {
      position: 'center',
      hide: true
    },
    cell: [
      nav_copy,
      tinycolor(nav_bg)
        .lighten(10)
        .toString()
    ]
  };
  const settings = chartSettings || chartSettingsInit;

  const {
    pieChart: { width, height },
    pie: { cx, cy, innerRadius, outerRadius, fill, stroke, paddingAngle },
    label: { position, hide },
    cell
  } = settings;
  // console.log('>>>', settings, '<<<');
  if (!hasData)
    return (
      <PieChartLoading width={width} height={height} color={nav_copy}>
        Loading...
      </PieChartLoading>
    );

  return (
    <PieChart width={width} height={height}>
      <Pie
        data={chartData}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        fill={fill}
        stroke={stroke}
        paddingAngle={paddingAngle}
      >
        {!hide && <Label value={chartData[0].name} position={position} />}
        {chartData.map((entry, index) => (
          <Cell fill={cell[index % cell.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

ChartPie.defaultPops = {
  chartSettings: null
};

export default ChartPie;
