import React from 'react';
import { Dialog, Like, Container } from 'components';
import { PreassessmentModalContainer, PreassessmentModalDescription, PreassessmentModalTitle } from '../style';
import { usePreassessmentContext } from '../contexts';

const PreassessmentModal = ({ open }) => {
  const { assessment } = usePreassessmentContext();
  return (
    <Dialog open={open} setOpen={() => {}} variant={'standard'} maxWidth="sm" onlyOk>
      <PreassessmentModalContainer justifyContent={'center'}>
        <Container.Grid lg={12} sm={12} justifyContent={'center'}>
          <Like />
        </Container.Grid>

        <PreassessmentModalTitle>Thank you for completing your {assessment.label} Assessment!</PreassessmentModalTitle>

        <PreassessmentModalDescription>
          Your responses have been submitted to management and there is no further action needed from you at this time.
          Thank you!
        </PreassessmentModalDescription>

        <PreassessmentModalDescription>You may now close this page</PreassessmentModalDescription>
      </PreassessmentModalContainer>
    </Dialog>
  );
};

export default PreassessmentModal;
