import apiClient from '../../apiClient/apiClient';
import { buildQueryString } from 'helpers/utils';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_19;
const baseURL = `https://${api}.${base}/${env}`;

const localURL = 'http://127.0.0.1:5000';

const bsnPartnersApi = {};

bsnPartnersApi.getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

bsnPartnersApi.audiLogsSearch = ({ search, filters = {} }) => {
  const queryParams = buildQueryString(filters);
  const requestOptions = {
    url: `${baseURL}/logs/search/${search}${queryParams}`,
    headers: bsnPartnersApi.getHeaders()
  };
  return apiClient.get(requestOptions);
};

bsnPartnersApi.audiLogs = ({ filters = {} }) => {
  const queryParams = buildQueryString(filters);
  const requestOptions = {
    url: `${baseURL}/logs${queryParams}`,
    headers: bsnPartnersApi.getHeaders()
  };
  return apiClient.get(requestOptions);
};

bsnPartnersApi.export = ({ filters = {} }) => {
  const queryParams = buildQueryString(filters);
  const requestOptions = {
    url: `${baseURL}/logs/export${queryParams}`,
    headers: bsnPartnersApi.getHeaders()
  };
  return apiClient.get(requestOptions);
};

bsnPartnersApi.submitTicket = payload => {
  const requestOptions = {
    url: `${baseURL}/createAuditLogTicket`,
    headers: bsnPartnersApi.getHeaders(),
    data: payload
  };
  return apiClient.post(requestOptions);
};

bsnPartnersApi.getQuicksightDashboards = () => {
  const requestOptions = {
    url: `${baseURL}/dashboards`,
    headers: bsnPartnersApi.getHeaders()
  };
  return apiClient.get(requestOptions);
};

bsnPartnersApi.getQuicksightDashboard = (dashboardId) => {
  const requestOptions = {
    url: `${baseURL}/${dashboardId}/dashboard`,
    headers: bsnPartnersApi.getHeaders()
  };
  return apiClient.get(requestOptions);
};

export default bsnPartnersApi;
