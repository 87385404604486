import React from 'react';
import { Typography, Avatar, InfoIcon, Stack, styled, alpha, AlertDialog } from '@trustsecurenow/components-library';

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: 70,
  height: 70,
  backgroundColor: alpha(theme.palette.error.main, 0.2)
}));

const UnPublishDialog = ({ open, packageName, onCloseDialog, unpublishText, onUnpublishPackage }) => {
  return (
    <AlertDialog
      open={open}
      onClose={onCloseDialog}
      dialogTitle={
        <Stack spacing={2} alignItems="center">
          <CustomAvatar>
            <InfoIcon color="error" sx={{ fontSize: 34 }} />
          </CustomAvatar>

          <Typography color="error" variant="h2">
            Are you sure you want to unpublish {packageName}?
          </Typography>
        </Stack>
      }
      message={unpublishText}
      SubmitButtonProps={{
        color: 'error',
        children: `Yes, unpublish ${packageName}`,
        onClick: onUnpublishPackage
      }}
    />
  );
};

export default UnPublishDialog;
