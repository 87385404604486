// @flow

import autoPhishCampaign from './autoPhishCampaign';
import newPhishingCampaign from './newPhishingCampaign';
import newAutophishCampaign from './newAutophishCampaign';
import scenarioPreview from './scenarioPreview';
import phishingReportsCampaign from './phishingReportsCampaign';
import policies from './policies';
import policyInfo from './policyInfo';
import otherPolicies from './otherPolicies';
import otherPolicyInfo from './otherPolicyInfo';
import trainingTrainings from './trainingTrainings';
import trainingUsers from './trainingUsers';
import trainingAnnual from './trainingAnnual';
import trackSecurityIncidents from './trackSecurityIncidents';
import trackSecurityIncidentsHIPAA from './trackSecurityIncidentsHIPAA';
import trackSecurityIncidentsInfo from './trackSecurityIncidentsInfo';
import trackServerRoomAccess from './trackServerRoomAccess';
import trackServerRoomAccessInfo from './trackServerRoomAccessInfo';
import documentsServiceProviderInfo from './documentsServiceProviderInfo';
import otherDocuments from './otherDocuments';
import otherDocumentsInfo from './otherDocumentsInfo';
import documentsServiceProvider from './documentsServiceProvider';
import documentsBusinessAssociateAgreement from './documentsBusinessAssociateAgreement';
import disasterRecovery from './disasterRecovery';
import disasterRecoveryInfo from './disasterRecoveryInfo';
import directorySync from './directorySync';
import user from './user';
import addUser from './addUser';
import customMessage from './customMessage';
import clientsTrainingCertificateViewModal from './clientsTrainingCertificateViewModal';
import tags from './tags';

const clients = {};

clients.autoPhishCampaign = autoPhishCampaign;
clients.newPhishingCampaign = newPhishingCampaign;
clients.newAutophishCampaign = newAutophishCampaign;
clients.scenarioPreview = scenarioPreview;
clients.phishingReportsCampaign = phishingReportsCampaign;
clients.policies = policies;
clients.policyInfo = policyInfo;
clients.otherPolicies = otherPolicies;
clients.otherPolicyInfo = otherPolicyInfo;
clients.trainingTrainings = trainingTrainings;
clients.trainingUsers = trainingUsers;
clients.trainingAnnual = trainingAnnual;
clients.trackSecurityIncidents = trackSecurityIncidents;
clients.trackSecurityIncidentsHIPAA = trackSecurityIncidentsHIPAA;
clients.trackSecurityIncidentsInfo = trackSecurityIncidentsInfo;
clients.trackServerRoomAccess = trackServerRoomAccess;
clients.trackServerRoomAccessInfo = trackServerRoomAccessInfo;
clients.documentsServiceProviderInfo = documentsServiceProviderInfo;
clients.otherDocuments = otherDocuments;
clients.otherDocumentsInfo = otherDocumentsInfo;
clients.documentsServiceProvider = documentsServiceProvider;
clients.documentsBusinessAssociateAgreement = documentsBusinessAssociateAgreement;
clients.disasterRecovery = disasterRecovery;
clients.disasterRecoveryInfo = disasterRecoveryInfo;
clients.directorySync = directorySync;
clients.user = user;
clients.addUser = addUser;
clients.customMessage = customMessage;
clients.clientsTrainingCertificateViewModal = clientsTrainingCertificateViewModal;
clients.tags = tags;

export default clients;
