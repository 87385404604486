import { Box } from '@material-ui/core'
import StatusButton from 'apps/shared/darkWebMonitoring/StatusButton'
import { useDirectorySync } from 'apps/shared/directorySync/DirectorySyncContext'
import EnableDisableWelcomeMessageDialog from 'apps/shared/users/modals/EnableDisableWelcomeMessageDialog'
import { CustomTooltip } from 'components'
import React from 'react'

const WelcomeMessageStatusButton = ({
    isWelcomeMsgDialogOpened,
    setIsWelcomeMsgDialogOpened,
    tooltipPlacement = "right"
}) => {
    const {
        state,
        dispatch,
    } = useDirectorySync();

    const handleOpenWelcomeMsgDialog = () => setIsWelcomeMsgDialogOpened(true);
    const handleCloseWelcomeMsgDialog = () => setIsWelcomeMsgDialogOpened(false);

    const handleSendWelcomeMsgToggler = () => {
        dispatch.onSwitch("automatedWelcome", !state.automatedWelcome);
        handleCloseWelcomeMsgDialog();
    }

    return (
        <>
            <Box>
                <CustomTooltip
                arrow
                light
                title={
                    state.automatedWelcome
                    ? "Welcome Message enabled. New Users will receive welcome messages by default. Click here to disable welcome messages."
                    : "Click here to enable welcome messages."
                }
                placement={tooltipPlacement}
                >
                <StatusButton
                    style={{ marginRight: 5 }}
                    status={state.automatedWelcome ? 'active' : 'inactive'}
                    label="Welcome Message"
                    onClick={handleOpenWelcomeMsgDialog}
                    inactiveOnClick={handleOpenWelcomeMsgDialog}
                />
                </CustomTooltip>
            </Box>

            <EnableDisableWelcomeMessageDialog 
                isWelcomeMsgEnabled={state.automatedWelcome} 
                openDialog={isWelcomeMsgDialogOpened}
                enableDialogIsCustomWelcomeMsg={state?.customWelcome}
                enableDialogWelcomeMsgFrequency={state?.welMessFrequency}
                enableDialogWelcomeMsgHowMany={state?.welMessHowMany}
                enableDialogWelcomeMsgDeferred={state?.deferred_sending}
                onCloseDialog={handleCloseWelcomeMsgDialog} 
                onSubmitEnable={handleSendWelcomeMsgToggler}
                onSubmitDisable={handleSendWelcomeMsgToggler}
            />
      </>
    )
}

export default WelcomeMessageStatusButton;
