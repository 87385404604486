import React from 'react';
import styled from 'styled-components';
import { Hidden } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import ChartCircle from '../charts/ChartCircle';
import ChartHalfCircle from '../charts/ChartHalfCircle';
import ChartEqualizer from '../charts/ChartEqualizer';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Pagination } from 'swiper';

const Box = styled.div`
  display: flex;
  box-sizing: border-box;
  min-width: 100px;
  flex: 1 ${({ shrink }) => shrink}%;
  padding: 10px;

  ${props => props.theme.breakpoints.down('sm')} {
    max-width: 576px;
    width: 100%;
    margin: 0 auto;
    &.dashboard-table {
      padding: 0 10px 10px;
    }
  }

  @media only screen and (min-width: 1235px) {
    flex: 1 ${({ shrinkLg }) => shrinkLg}%;
  }
  ${({ opacity }) => opacity && `opacity: 0.1`};
`;

const SliderContainer = styled.div`
  background: var(--backgroundPaper);
  margin: 0 auto;
  padding-bottom: 20px;
  max-width: 556px;
  width: calc(100% - 20px);

  .swiper-pagination {
    bottom: 0 !important;
  }
`;

const Content = styled.div`
  flex: 1 100%;
  background-color: var(--backgroundPaper);
  padding: 20px;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
    box-sizing: border-box;

    &.group-widget {
      padding: 20px 0;
    }
  }

  .chart-circle-md {
    width : 70%;
    margin : auto; 
  }
`;

const defaultChartLineData = [
  {
    name: '0-0',
    value: 0
  },
  {
    name: '0-0',
    value: 0
  },
  {
    name: '0-0',
    value: 0
  },
  {
    name: '0-0',
    value: 0
  },
  {
    name: '0-0',
    value: 0
  },
  {
    name: '0-0',
    value: 0
  }
];

const DesktopCircleCharts = ({ essChart, isDashboard, showBox, chartLine, externalDataBreaches, box, open, size='default'}) => {
  return (
    <Hidden smDown>
      {essChart?.ess >= 0 && isDashboard.ess && (
        <Box shrink="40" opacity={open && showBox(open, essChart.title, box)}>
          <Content>
            {/* TODO: Change essChart API for a Object */}
            <ChartHalfCircle
              // title="Employee Secure Score (ESS)"
              title={essChart.title}
              description={essChart.description}
              chart={{
                chartData: [{ name: essChart.ess, value: (essChart.ess / 800) * 100 }]
              }}
              labels
              className = {size==='medium'? 'chart-circle-md' : null}
            />
          </Content>
        </Box>
      )}
      {chartLine && chartLine.data && isDashboard.ess_trend && (
        <Box shrink="60" opacity={open}>
          <Content>
            <ChartEqualizer
              title={chartLine.title}
              chart={chartLine.data.length > 0 ? chartLine : { ...chartLine, data: defaultChartLineData }}
              size={size==='medium'? 'medium' : 'default'}
            />
          </Content>
        </Box>
      )}

      {externalDataBreaches && isDashboard.external_data_breaches && (
        <Box shrink="40" opacity={open && showBox(open, externalDataBreaches.title, box)}>
          <Content>
            <ChartCircle {...externalDataBreaches} 
              fontSize={13} 
              icon 
              labels 
              className = {size==='medium'? 'chart-circle-md' : null}
            />
          </Content>
        </Box>
      )}
    </Hidden>
  );
};

const MemoDesktopCircleCharts = React.memo(DesktopCircleCharts);

const MobileCircleCharts = ({ essChart, isDashboard, showBox, chartLine, externalDataBreaches, box, open }) => {
  return (
    <Hidden mdUp>
      <SliderContainer>
        <Swiper spaceBetween={50} pagination={true} modules={[Pagination]}>
          {essChart?.ess >= 0 && isDashboard.ess && (
            <SwiperSlide>
              <Box shrink="40" opacity={open && showBox(open, essChart.title, box)}>
                <Content>
                  {/* TODO: Change essChart API for a Object */}
                  <ChartHalfCircle
                    // title="Employee Secure Score (ESS)"
                    title={essChart.title}
                    description={essChart.description}
                    chart={{
                      chartData: [{ name: essChart.ess, value: (essChart.ess / 800) * 100 }]
                    }}
                    labels
                  />
                </Content>
              </Box>
            </SwiperSlide>
          )}

          {chartLine && chartLine.data && isDashboard.ess_trend && (
            <SwiperSlide>
              <Box shrink="60" opacity={open}>
                <Content>
                  <ChartEqualizer
                    title={chartLine.title}
                    chart={chartLine.data.length > 0 ? chartLine : { ...chartLine, data: defaultChartLineData }}
                  />
                </Content>
              </Box>
            </SwiperSlide>
          )}

          {externalDataBreaches && isDashboard.external_data_breaches && (
            <SwiperSlide>
              <Box shrink="40" opacity={open && showBox(open, externalDataBreaches.title, box)}>
                <Content>
                  <ChartCircle {...externalDataBreaches} fontSize={13} icon labels />
                </Content>
              </Box>
            </SwiperSlide>
          )}
        </Swiper>
      </SliderContainer>
    </Hidden>
  );
};

const MemoMobileCircleCharts = React.memo(MobileCircleCharts);

const CircleCharts = props => {
  return (
    <>
      <MemoDesktopCircleCharts {...props} />
      <MemoMobileCircleCharts {...props} />
    </>
  );
};

export default CircleCharts;
