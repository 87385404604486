// @flow
import React from 'react';
import { Container, Typography, LazyIcon, TextField, Button, LoadingStyled, Dialog } from 'components';
import { ExpansionPanelDetails as AccordionDetails } from '@material-ui/core';
import { AccordionSummary, CancelButton, SimilarPartnersAccordion } from '../../style/AdminCommon';
import { usePartnerAddEdit } from '../../hooks';
import PartnerInfoRow from './PartnerInfoRow';
import PartnersNotes from './PartnersNotes';

const PartnersConflict = () => {
  const { loading, record, dispatch, similarPartners, openArchiveDialog, notesData } = usePartnerAddEdit();
  const similarPartnersTotal = similarPartners?.length || 0;

  if (loading) return <LoadingStyled />;

  return (
    <>
      <Container.Grid block spacing={24} pt={2}>
        <Container.Grid item block direction="column" xs={12}>

          <Container.Paper fullWidth radius={2}>
            <Container.Grid container>

              <Container.Grid item bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container>
                  <Container.Grid item xs={6} sm={2} md={3} lg={3}>
                    <Typography.h3 p={2} m={0.1} fontSize={14}>
                      Organization
                    </Typography.h3>
                  </Container.Grid>
                  <Container.Grid item xs={6} sm={2} md={2} lg={2}>
                    <Typography.h3 p={2} m={0.1} fontSize={14}>
                      Email
                    </Typography.h3>
                  </Container.Grid>
                  <Container.Grid item xs={6} sm={2} md={3} lg={3}>
                    <Typography.h3 p={2} m={0.1} fontSize={14}>
                      Link
                    </Typography.h3>
                  </Container.Grid>
                  <Container.Grid item xs={6} sm={2} md={2} lg={2}>
                    <Typography.h3 p={2} m={0.1} fontSize={14}>
                      Distributor
                    </Typography.h3>
                  </Container.Grid>
                  <Container.Grid item xs={6} sm={2} md={2} lg={2}>
                    <Typography.h3 p={2} m={0.1} fontSize={14}>
                      Partner active
                    </Typography.h3>
                  </Container.Grid>

                </Container.Grid>
              </Container.Grid>

              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container>
                  <PartnerInfoRow item={record} />
                </Container.Grid>
              </Container.Grid>

            </Container.Grid>
          </Container.Paper>

          <Container.Paper fullWidth mt={2} mb={2}>
            <Container.Grid container>
              <Container.Grid item bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container>
                  <Container.Grid item xs={6} sm={2} md={2} lg={2}>
                    <Typography.h3 p={2} m={0.1} fontSize={14}>
                      Notes
                    </Typography.h3>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>

              <Container.Grid item pl={2} pt={2} pb={2} xs={12} sm={12} md={6} lg={5} xl={4}>
                <TextField
                  label="Enter information that maybe can be important"
                  name="notes"
                  value={record.notes}
                  onChange={({ target: { name, value } }) => dispatch.onChange(name, value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Container.Grid>

              <Container.Grid pl={1} pr={2}>
                {notesData && <PartnersNotes data={notesData} />}
              </Container.Grid>
            </Container.Grid>
          </Container.Paper>

          <SimilarPartnersAccordion>
            <AccordionSummary 
              expandIcon={
                <LazyIcon component="ArrowDown" size={1} color="colorSystemWarning" />
              }
            >
              <LazyIcon component="Info" color="colorSystemWarning" mt={2} mr={2} />
              <Typography.p color="colorSystemWarning">
                {`${similarPartnersTotal} Similar records were found!`}
              </Typography.p>
            </AccordionSummary>
            <AccordionDetails>
              <Container.Grid container>
                {similarPartners?.map((item, index) => (
                  <Container.Grid item md={12} lg={12} key={item.id} bb={(index + 1) !== similarPartnersTotal ? 1 : 0}>
                    <Container.Grid container>
                      <PartnerInfoRow item={item} isSimilar />
                    </Container.Grid>
                  </Container.Grid>
                ))}
              </Container.Grid>
            </AccordionDetails>
          </SimilarPartnersAccordion>

          <Container.Paper fullWidth radiusTop={2} radiusBottom={2} p={2} mt={2}>
            <Container.Grid container alignItems="center">
              <Container.Grid item md={6}>
                <Button
                  backgroundColor="colorSystemInfo"
                  backgroundColorHover="colorSystemInfoHover"
                  onClick={() => dispatch.save('save')}
                >
                  <LazyIcon component="Save" color="colorCommonWhite" mr={1} />
                  Save
                </Button>
              </Container.Grid>

              <Container.Grid item md={6} justify="flex-end">
                <CancelButton mr={2} onClick={() => dispatch.setOpenArchiveDialog(true)}>
                  Archive
                </CancelButton>
                <Button
                  backgroundColor="colorSystemSuccess"
                  backgroundColorHover="colorSystemSuccessHover"
                  onClick={() => dispatch.save('approve')}
                >
                  <LazyIcon component="LikeIcon" color="colorCommonWhite" mr={1} />
                  Approve
                </Button>
              </Container.Grid>
            </Container.Grid>
          </Container.Paper>
        </Container.Grid>
      </Container.Grid>

      <Dialog
        title="Archive Partner"
        message="This Partner will be archived. Are you sure about that?"
        buttonSubmit="Yes, I want to archive"
        open={openArchiveDialog || false}
        setOpen={dispatch.setOpenArchiveDialog}
        maxWidth="xs"
        onSubmit={dispatch.archive}
      />
    </>
  )
};

export default PartnersConflict;